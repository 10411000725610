const Dashboard = () => import('@/views/Dashboard').then(m => m.default || m);
const FormLogin = () => import('@/views/FormLogin').then(m => m.default || m);
const AlertDisaster = () =>
  import('@/views/AlertDisaster').then(m => m.default || m);
const Hoax = () => import('@/views/Hoax').then(m => m.default || m);
const IssueMedia = () => import('@/views/IssueMedia').then(m => m.default || m);
const Aduan = () => import('@/views/Aduan2').then(m => m.default || m);
const Pikobar = () => import('@/views/Pikobar').then(m => m.default || m);
const IKP = () => import('@/views/IKP').then(m => m.default || m);
const SapaWarga = () => import('@/views/SapaWarga').then(m => m.default || m);
const ProgramJuara = () =>
  import('@/views/ProgramJuara').then(m => m.default || m);
const Proyek = () =>
  import('@/views/ProyekStrategis').then(m => m.default || m);
const KeuanganDaerah = () =>
  import('@/views/KeuanganDaerah').then(m => m.default || m);
const KeuanganDaerah2 = () =>
  import('@/views/KeuanganDaerah2').then(m => m.default || m);
const PengadaanBarangJasa = () =>
  import('@/views/PengadaanBarangJasa').then(m => m.default || m);
const Pendapatan = () =>
  import('@/views/PendapatanDaerah').then(m => m.default || m);
const Kerjasama = () => import('@/views/Kerjasama').then(m => m.default || m);
const Prestasi = () => import('@/views/Prestasi').then(m => m.default || m);
const Gis = () => import('@/views/Gis').then(m => m.default || m);
const Kewilayahan = () =>
  import('@/views/Kewilayahan').then(m => m.default || m);
const DpmDesa = () => import('@/views/DpmDesa').then(m => m.default || m);
const IKU = () => import('@/views/IKU').then(m => m.default || m);
const NotFound = () => import('@/views/404').then(m => m.default || m);
const Emonev = () => import('@/views/Emonev').then(m => m.default || m);

export default [
  {
    path: '/',
    component: FormLogin,
    name: 'FormLogin'
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'Dashboard'
  },
  {
    path: '/disaster',
    component: AlertDisaster,
    name: 'AlertDisaster'
  },
  {
    path: '/hoax',
    component: Hoax,
    name: 'hoax'
  },
  {
    path: '/issuemedia',
    component: IssueMedia,
    name: 'issuemedia'
  },
  {
    path: '/aduan',
    component: Aduan,
    name: 'Aduan'
  },
  {
    path: '/pikobar',
    component: Pikobar,
    name: 'pikobar'
  },
  {
    path: '/ikp',
    component: IKP,
    name: 'IKP'
  },
  {
    path: '/sapa-warga',
    component: SapaWarga,
    name: 'sapa-warga'
  },
  {
    path: '/program-juara',
    component: ProgramJuara,
    name: 'program-juara'
  },
  {
    path: '/proyek-strategis',
    component: Proyek,
    name: 'Proyek'
  },
  {
    path: '/keuangan-daerah',
    component: KeuanganDaerah,
    name: 'keuangan-daerah'
  },
  {
    path: '/keuangan-daerah2',
    component: KeuanganDaerah2,
    name: 'keuangan-daerah2'
  },
  {
    path: '/pendapatan-daerah',
    component: Pendapatan,
    name: 'Pendapatan'
  },
  {
    path: '/pengadaan-barang-dan-jasa',
    component: PengadaanBarangJasa,
    name: 'pengadaan-barang-dan-jasa'
  },
  {
    path: '/kerjasama',
    component: Kerjasama,
    name: 'Kerjasama'
  },
  {
    path: '/prestasi',
    component: Prestasi,
    name: 'Prestasi'
  },
  {
    path: '/gis-aset',
    component: Gis,
    name: 'gis-aset'
  },
  {
    path: '/kewilayahan',
    component: Kewilayahan,
    name: 'kewilayahan'
  },
  {
    path: '/emonev',
    component: Emonev,
    name: 'emonev'
  },
  {
    path: '/iku',
    component: IKU,
    name: 'IKU'
  },
  {
    path: '/dpm-desa',
    component: DpmDesa,
    name: 'dpmdesa'
  },
  {
    path: '*',
    component: NotFound,
    name: 'NotFound'
  }
];
