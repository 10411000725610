<template>
  <div :class="`sidebar h-100 overflow-y-auto ${sidebar ? 'active' : ''}`">
    <ul class="sidebar-inner bg-blue py-3">
      <li class="sidebar-inner--item" title="Disaster">
        <router-link
          :class="[
            currentPage.includes('disaster') ? activeClass : '',
            'sidebar-inner--link'
          ]"
          to="/disaster"
        >
          <img src="@/assets/img/icon/icon-sirine.svg" alt width="25" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Hoax">
        <router-link
          to="/hoax"
          :class="[
            currentPage.includes('hoax') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-hoax.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Issue Media">
        <router-link
          :class="[
            currentPage.includes('issuemedia') ? activeClass : '',
            'sidebar-inner--link'
          ]"
          to="/issuemedia"
        >
          <img src="@/assets/img/icon/icon-flag.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Aduan">
        <router-link
          :class="[
            currentPage.includes('aduan') ? activeClass : '',
            'sidebar-inner--link'
          ]"
          to="/aduan"
        >
          <img src="@/assets/img/icon/icon-complaint.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="PIKOBAR">
        <router-link
          to="/pikobar"
          :class="[
            currentPage.includes('pikobar') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-pikobar.svg" alt width="20" />
        </router-link>
      </li>
    </ul>
    <ul class="sidebar-inner bg-success py-3">
      <li class="sidebar-inner--item" title="IKP">
        <router-link
          to="/ikp"
          :class="[
            currentPage.includes('ikp') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-finance.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="SapaWarga">
        <router-link
          to="/sapa-warga"
          :class="[
            currentPage.includes('sapa-warga') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-chat.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Program Juara">
        <router-link
          to="/program-juara"
          :class="[
            currentPage.includes('program-juara') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-program-juara.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Proyek Strategis">
        <router-link
          to="/proyek-strategis"
          :class="[
            currentPage.includes('proyek-strategis') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-progress.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Keuangan Daerah">
        <router-link
          to="/keuangan-daerah"
          :class="[
            currentPage.includes('keuangan-daerah') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-money.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Pendapatan Daerah">
        <router-link
          to="/pendapatan-daerah"
          :class="[
            currentPage.includes('pendapatan-daerah') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-moneyincrease.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Pengadaan Barang dan Jasa">
        <router-link
          to="/pengadaan-barang-dan-jasa"
          :class="[
            currentPage.includes('pengadaan-barang-dan-jasa')
              ? activeClass
              : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-sipakar.png" alt width="20" />
        </router-link>
      </li>
      <!-- <li class="sidebar-inner--item">
				<router-link to="/iku" :class="[currentPage.includes('iku') ? activeClass : '','sidebar-inner--link']">
					<img src="@/assets/img/icon/icon-performance.svg" alt width="20" />
				</router-link>
			</li> -->
      <li class="sidebar-inner--item" title="Kerjasama">
        <router-link
          to="/kerjasama"
          :class="[
            currentPage.includes('kerjasama') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-target.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Prestasi">
        <router-link
          :class="[
            currentPage.includes('prestasi') ? activeClass : '',
            'sidebar-inner--link'
          ]"
          to="/prestasi"
        >
          <img src="@/assets/img/icon/icon-awards.svg" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Gis Aset">
        <router-link
          to="/gis-aset"
          :class="[
            currentPage.includes('gis-aset') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-gis-aset.png" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Kewilayahan">
        <router-link
          to="/kewilayahan"
          :class="[
            currentPage.includes('kewilayahan') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-sikwil.png" alt width="20" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Emonev">
        <router-link
          to="/emonev"
          :class="[
            currentPage.includes('emonev') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-emonev.png" alt width="30" />
        </router-link>
      </li>
      <li class="sidebar-inner--item" title="Dpm Desa">
        <router-link
          to="/dpm-desa"
          :class="[
            currentPage.includes('dpmdesa') ? activeClass : '',
            'sidebar-inner--link'
          ]"
        >
          <img src="@/assets/img/icon/icon-dpm-desa.png" alt width="20" />
        </router-link>
      </li>
      <!-- <li class="sidebar-inner--item">
				<router-link :class="[currentPage.includes('dashboard') ? activeClass : '','sidebar-inner--link']" to="/dashboard">
					<img src="@/assets/img/icon/icon-grid.svg" alt width="20" />
				</router-link>
			</li> -->
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      activeClass: 'active'
    };
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
    sidebar() {
      return this.$store.state.sidebar;
    },
    ...mapState(['nodeEnv'])
  }
};
</script>

<style></style>
