<template>
  <div id="dashboard">
    <span>
      <Navbar></Navbar>
    </span>
    <div class="main-layout">
      <div class="main-layout__sidebar">
        <Sidebar></Sidebar>
        <SidebarOver></SidebarOver>
      </div>

      <div class="main-layout__main" @click="side">
        <child></child>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Sidebar from '@/components/Sidebar.vue';
import SidebarOver from '@/components/SidebarOver.vue';
export default {
  components: {
    Navbar,
    Sidebar,
    SidebarOver
  },
  mounted() {
    if (this.$store.getters.getToken == '') {
      this.$router.push({ path: '/' });
    }
  },
  methods: {
    side() {
      this.$store.commit('sidebar', false);
    }
  }
};
</script>
