import Vue from 'vue';
import routes from './routes';
import VueRouter from 'vue-router';
import store from '@/store';
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: window.base_path,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeResolve((to, from, next) => {
  store.commit('changeLoading', true);
  setTimeout(() => {
    next();
  }, 500);
});

router.afterEach(() => {
  setTimeout(() => {
    store.commit('changeLoading', false);
  }, 500);
  store.commit('sidebar', false);
});

export default router;
