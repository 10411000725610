import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

Vue.use(Vuex);
const host = location.host;

export default new Vuex.Store({
  state: {
    host: host,
    nodeEnv: process.env.VUE_APP_BASE_URL_PROD.includes(host)
      ? 'production'
      : 'development',
    isLoading: true,
    sidebar: false,
    token: '',
    lokasi: '',
    user: {},
    isDarkMode: false
  },

  getters: {
    getToken: state => {
      if (Cookies.get('token')) {
        state.token = Cookies.get('token');
      }
      return state.token;
    },
    getUser: state => {
      if (Cookies.get('user')) {
        state.user = JSON.parse(Cookies.get('user'));
      }
      return state.user;
    },
    getLokasi: state => {
      if (Cookies.get('lokasi')) {
        state.lokasi = Cookies.get('lokasi');
      }
      return state.lokasi;
    },
    getAvatar: state => {
      if (state.user.avatar != null) {
        return state.user.url_file;
      } else {
        return '@/assets/img/navigation/biografi-ridwan-kamil.jpg';
      }
    }
  },

  mutations: {
    changeLoading(state, status) {
      state.isLoading = status;
    },
    sidebar(state, status) {
      state.sidebar = status;
    },
    saveToken(state, token) {
      state.token = token;
      Cookies.set('token', token, { expires: 7, path: '/' });
    },
    saveLokasi(state, lokasi) {
      state.lokasi = lokasi;
      Cookies.set('lokasi', lokasi);
    },
    removeLokasi(state) {
      state.lokasi = '';
    },
    removeToken(state) {
      state.token = '';
      state.lokasi = '';
      state.user = {};
      Cookies.remove('token');
      Cookies.remove('user');
      Cookies.remove('lokasi');
    },
    saveUser(state, user) {
      state.user = user;
      Cookies.set('user', JSON.stringify(user), { expires: 1 });
    },
    toggleDarkMode(state) {
      state.isDarkMode = !state.isDarkMode;
    }
  }
});
