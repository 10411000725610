<template>
  <div :class="`sidebar-overlay overflow-y-auto ${sidebar ? 'active' : ''}`">
    <ul class="sidebar-overlay-inner bg-blue">
      <li class="sidebar-overlay-item">
        <router-link to="/disaster" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-sirine.svg" alt="" width="20" />
          <p>
            ALERT DISASTER
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/hoax" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-hoax.svg" alt="" width="20" />
          <p>
            HOAX
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/issuemedia" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-flag.svg" alt="" width="20" />
          <p>
            ISSUE MEDIA
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/aduan" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-complaint.svg" alt="" width="20" />
          <p>
            ADUAN
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/pikobar" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-pikobar.svg" alt="" width="20" />
          <p>
            PIKOBAR
          </p>
        </router-link>
      </li>
    </ul>
    <ul class="sidebar-overlay-inner bg-green">
      <li class="sidebar-overlay-item">
        <router-link to="/ikp" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-finance.svg" alt="" width="20" />
          <p>
            IKP
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/sapa-warga" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-chat.svg" alt="" width="20" />
          <p>
            SAPA WARGA
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/program-juara" class="sidebar-overlay-link">
          <img
            src="@/assets/img/icon/icon-program-juara.svg"
            alt=""
            width="20"
          />
          <p>
            PROGRAM JUARA
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/proyek-strategis" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-progress.svg" alt="" width="20" />
          <p>
            PROYEK STRATEGIS
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/keuangan-daerah" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-money.svg" alt="" width="20" />
          <p>
            KEUANGAN DAERAH
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/pendapatan-daerah" class="sidebar-overlay-link">
          <img
            src="@/assets/img/icon/icon-moneyincrease.svg"
            alt=""
            width="20"
          />
          <p>
            PENDAPATAN DAERAH
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link
          to="/pengadaan-barang-dan-jasa"
          class="sidebar-overlay-link"
        >
          <img src="@/assets/img/icon/icon-sipakar.png" alt="" width="20" />
          <p>
            PENGADAAN BARANG DAN JASA
          </p>
        </router-link>
      </li>
      <!-- <li class="sidebar-overlay-item">
            <router-link to="/iku" class="sidebar-overlay-link">
                <img src="@/assets/img/icon/iku.svg" alt="" width="20">
                <p>
                    IKU
                </p>
            </router-link>
        </li> -->
      <li class="sidebar-overlay-item">
        <router-link to="/kerjasama" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-target.svg" alt="" width="20" />
          <p>
            KERJASAMA
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/prestasi" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-awards.svg" alt="" width="20" />
          <p>
            PRESTASI
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/gis-aset" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-gis-aset.png" alt="" width="20" />
          <p>
            GIS ASET
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/kewilayahan" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-sikwil.png" alt="" width="20" />
          <p>
            KEWILAYAHAN
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/emonev" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-emonev.png" alt="" width="30" />
          <p>
            EMONEV
          </p>
        </router-link>
      </li>
      <li class="sidebar-overlay-item">
        <router-link to="/dpm-desa" class="sidebar-overlay-link">
          <img src="@/assets/img/icon/icon-dpm-desa.png" alt="" width="20" />
          <p>
            DPM DESA
          </p>
        </router-link>
      </li>
      <!-- <li class="sidebar-overlay-item">
            <router-link to="/dashboard" class="sidebar-overlay-link">
                <img src="@/assets/img/icon/icon-grid.svg" alt="" width="20">
                <p>
                    DASHBOARD
                </p>
            </router-link>
        </li> -->
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    sidebar() {
      return this.$store.state.sidebar;
    },
    ...mapState(['nodeEnv'])
  }
};
</script>
