var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("sidebar h-100 overflow-y-auto " + (_vm.sidebar ? 'active' : ''))},[_c('ul',{staticClass:"sidebar-inner bg-blue py-3"},[_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Disaster"}},[_c('router-link',{class:[
            _vm.currentPage.includes('disaster') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/disaster"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-sirine.svg"),"alt":"","width":"25"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Hoax"}},[_c('router-link',{class:[
            _vm.currentPage.includes('hoax') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/hoax"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-hoax.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Issue Media"}},[_c('router-link',{class:[
            _vm.currentPage.includes('issuemedia') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/issuemedia"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-flag.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Aduan"}},[_c('router-link',{class:[
            _vm.currentPage.includes('aduan') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/aduan"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-complaint.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"PIKOBAR"}},[_c('router-link',{class:[
            _vm.currentPage.includes('pikobar') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/pikobar"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-pikobar.svg"),"alt":"","width":"20"}})])],1)]),_c('ul',{staticClass:"sidebar-inner bg-success py-3"},[_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"IKP"}},[_c('router-link',{class:[
            _vm.currentPage.includes('ikp') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/ikp"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-finance.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"SapaWarga"}},[_c('router-link',{class:[
            _vm.currentPage.includes('sapa-warga') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/sapa-warga"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-chat.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Program Juara"}},[_c('router-link',{class:[
            _vm.currentPage.includes('program-juara') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/program-juara"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-program-juara.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Proyek Strategis"}},[_c('router-link',{class:[
            _vm.currentPage.includes('proyek-strategis') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/proyek-strategis"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-progress.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Keuangan Daerah"}},[_c('router-link',{class:[
            _vm.currentPage.includes('keuangan-daerah') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/keuangan-daerah"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-money.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Pendapatan Daerah"}},[_c('router-link',{class:[
            _vm.currentPage.includes('pendapatan-daerah') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/pendapatan-daerah"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-moneyincrease.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Pengadaan Barang dan Jasa"}},[_c('router-link',{class:[
            _vm.currentPage.includes('pengadaan-barang-dan-jasa')
              ? _vm.activeClass
              : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/pengadaan-barang-dan-jasa"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-sipakar.png"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Kerjasama"}},[_c('router-link',{class:[
            _vm.currentPage.includes('kerjasama') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/kerjasama"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-target.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Prestasi"}},[_c('router-link',{class:[
            _vm.currentPage.includes('prestasi') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/prestasi"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-awards.svg"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Gis Aset"}},[_c('router-link',{class:[
            _vm.currentPage.includes('gis-aset') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/gis-aset"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-gis-aset.png"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Kewilayahan"}},[_c('router-link',{class:[
            _vm.currentPage.includes('kewilayahan') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/kewilayahan"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-sikwil.png"),"alt":"","width":"20"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Emonev"}},[_c('router-link',{class:[
            _vm.currentPage.includes('emonev') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/emonev"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-emonev.png"),"alt":"","width":"30"}})])],1),_c('li',{staticClass:"sidebar-inner--item",attrs:{"title":"Dpm Desa"}},[_c('router-link',{class:[
            _vm.currentPage.includes('dpmdesa') ? _vm.activeClass : '',
            'sidebar-inner--link'
          ],attrs:{"to":"/dpm-desa"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/icon-dpm-desa.png"),"alt":"","width":"20"}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }