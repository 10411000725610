<template>
  <span>
    <loading
      :active.sync="isLoading"
      :is-full-page="fullPage"
      :opacity="0.0"
      :z-index="1000"
      color="#01a7e5"
      :width="70"
      :height="70"
      transition="slide-fade"
      loader="bars"
    ></loading>
    <component :is="layout" />
  </span>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';

import dashboard from '@/layouts/Dashboard';
import login from '@/layouts/Login';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    Loading,
    dashboard,
    login
  },
  metaInfo() {
    const appName = window.config;

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    };
  },
  data: () => ({
    fullPage: true
  }),
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    layout() {
      if (this.$route.path === '/') {
        return 'login';
      } else {
        var body = document.querySelector('body');
        body.style.overflowY = 'auto';
        return 'dashboard';
        // return 'login';
      }
    }
  },
  mounted() {
    if (this.$store.getters.getToken == '') {
      this.$store.commit('removeToken');
      // this.$router.push({ path: "/" }).catch(() => {});
    }

    if (this.$store.getters.getToken != '') {
      window.axios
        .get(window.base_api + 'user', {
          headers: { Authorization: 'Bearer ' + this.$store.getters.getToken }
        })
        .then(response => {
          if (response.status == 200 && response.data.status == 'success') {
            this.$store.commit('saveUser', response.data.user);
          } else {
            this.$store.commit('removeToken');
            this.$router.push({ path: '/' }).catch(() => {});
          }
        })
        .catch(() => {
          this.$store.commit('removeToken');
          this.$router.push({ path: '/' }).catch(() => {});
        });
    }
    if (this.$store.getters.getLokasi == '') {
      this.$getLocation({}, false).then(coordinates => {
        this.lat = coordinates.lat;
        this.lng = coordinates.lng;
        var latLngObj = {
          lat: this.lat,
          lng: this.lng
        };
        this.$geocoder.send(latLngObj, response => {
          if (response.status == 'OK') {
            this.$store.commit(
              'saveLokasi',
              response.results[0].address_components[4].short_name
            );
          } else {
            this.$store.commit('removeLokasi');
          }
        });
      });
    }
  },

  methods: {}
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<style>
.grid-section-item .card-dashboard-content {
  overflow-y: visible;
  height: auto;
}
.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 5px;
  padding: 5px 10px 4px;
  text-align: left;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}

.tooltip[x-placement^='top'] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}

.tooltip[x-placement^='bottom'] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^='right'] {
  margin-left: 5px;
}

.tooltip[x-placement^='right'] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^='left'] {
  margin-right: 5px;
}

.tooltip[x-placement^='left'] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

@media (min-width: 1920px) and (max-width: 5760px) and (orientation: portrait) {
  .doc iframe {
    height: 830px !important;
  }
}

@media (min-width: 1920px) and (max-width: 5760px) and (orientation: landscape) {
  .doc iframe {
    height: 830px !important;
  }
}
</style>
