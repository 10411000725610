import '@/assets/app2.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Chart from 'chart.js';
import VAnimateCss from 'animate.css';
import VueLazyLoad from 'vue-lazyload';
import { sync } from 'vuex-router-sync';
import VueRangedatePicker from 'vue-rangedate-picker';
import VueMeta from 'vue-meta';
import VueGeolocation from 'vue-browser-geolocation';
import Geocoder from '@pderas/vue2-geocoder';
import moment from 'moment';
import HighchartsVue from 'highcharts-vue';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip);
Vue.use(HighchartsVue);
Vue.use(VueMeta);
Vue.use(VAnimateCss);
Vue.use(VueLazyLoad);
Vue.use(VueGeolocation);
Vue.use(VueRangedatePicker);
Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: 'lat-lng', // or 'lat-lng'
  googleMapsApiKey: 'AIzaSyA4HVCPlwt3KusJxpB3e_oAuw9_zaB9UJQ'
});

Vue.prototype.$eventHub = new Vue();
window.moment = moment;
window._ = require('lodash');

try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');

  require('bootstrap');
} catch (e) {
  // console.log(e);
}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const baseUrl =
  store.state.nodeEnv == 'production'
    ? process.env.VUE_APP_BASE_URL_PROD
    : process.env.VUE_APP_BASE_URL_STAGING;

window.links = {
  base_api:
    store.state.nodeEnv == 'production'
      ? process.env.VUE_APP_BASE_API_PROD
      : process.env.VUE_APP_BASE_API_STAGING,
  map_strategis: process.env.VUE_APP_MAP_STRATEGIS,
  gis_aset: process.env.VUE_APP_GIS_ASET,
  kewilayahan: process.env.VUE_APP_KEWILAYAHAN,
  kewilayahan_dark: process.env.VUE_APP_KEWILAYAHAN_DARK,
  aduan: process.env.VUE_APP_ADUAN,
  aduan_dark: process.env.VUE_APP_ADUAN_DARK,
  pikobar: process.env.VUE_APP_PIKOBAR,
  pikobar_init: process.env.VUE_APP_PIKOBAR_INIT,
  disaster: process.env.VUE_APP_DISASTER,
  disaster_dark: process.env.VUE_APP_DISASTER_DARK,
  notif_disaster: process.env.VUE_APP_NOTIF_DISASTER,
  entity: process.env.VUE_APP_ENTITY,
  issueMedia: process.env.VUE_APP_ISSUEMEDIA,
  issueMedia_dark: process.env.VUE_APP_ISSUEMEDIA_DARK,
  white: process.env.VUE_APP_WHITE,
  dark: process.env.VUE_APP_DARK,
  hoax: process.env.VUE_APP_HOAX,
  hoax_dark: process.env.VUE_APP_HOAX_DARK,
  emonev_api: process.env.VUE_APP_MANPRO,
  pendapatan_daerah: process.env.VUE_APP_PENDAPATAN_DAERAH,
  pengadaan_barang_jasa: process.env.VUE_APP_PBJ,
  pengadaan_barang_jasa_dark: process.env.VUE_APP_PBJ_DARK,
  dpm_desa: process.env.VUE_APP_DPM_DESA,
  dpm_desa_dark: process.env.VUE_APP_DPM_DESA_DARK
};

const links = window.links;
window.nodeEnv = store.state.nodeEnv;
window.base_api = links.base_api;
window.base_asset = baseUrl + '/';
window.base_path = baseUrl + '/';
window.base_segment = 'disaster';
window.date_now = moment().format('Y-MM-D');
window.emonev_api = window.links.emonev_api;
const thisYear = moment().format('Y');
const startYear = 2019;
window.date_years = Array(thisYear - (thisYear - (thisYear - startYear + 1)))
  .fill('')
  .map((v, idx) => thisYear - idx);
const localeData = moment.localeData('id');
window.date_month = localeData.months();
window.config = 'Executive Dashboard';
window.Chart = Chart;
Vue.config.productionTip = false;

const child = () => import('@/components/Child').then(m => m.default || m);
Vue.component('child', child);

sync(store, router);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

/* new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
}); */
