<template>
  <nav class="navigation navigation-header py-2">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <a
            href="#"
            class="burger-menu mr-3"
            v-on:click="sidebarChange(sidebar)"
          >
            <i class="fas fa-bars"></i>
          </a>
          <a href="#">
            <img class="img-fluid p-2" :src="logo" width="140" alt="" />
          </a>
        </div>
        <div class="col-md-9 px-0">
          <div
            class="navigation-inner d-inline-flex justify-content-between align-items-center"
          >
            <div class="navigation-info ml-3">
              <ul class="d-flex mb-0">
                <li class="d-flex mr-3">
                  <img
                    src="@/assets/img/icon/icon-location.svg"
                    alt=""
                    class="mr-2"
                    width="10"
                  />
                  <p class="mb-0 text-size-normal">
                    {{
                      this.$store.getters.getLokasi != ''
                        ? this.$store.getters.getLokasi
                        : 'Izin membaca lokasi Anda tidak tersedia'
                    }}
                  </p>
                </li>
                <!-- <li class="d-flex">
                                <img src="@/assets/img/icon/icon-weather.svg" alt="" class="mr-2" width="23">
                                <p class="mb-0 text-size-normal">
                                    22&#176;C
                                </p>
                            </li> -->
              </ul>
            </div>
            <div class="navigation-user d-flex align-items-center">
              <!-- <div class="navigation-user-alert mr-3">
                            <a href="#" data-toggle="modal" data-target="#modalAlert">
                                <img src="@/assets/img/icon/icon-ring.svg" alt="" width="25">
                            </a>
                        </div> -->
              <div
                class="navigation-user-maps d-flex align-items-center rounded-pill mr-3"
              >
                <label class="switch mx-2">
                  <input
                    type="checkbox"
                    @change="changeStyle"
                    :checked="isDarkMode"
                    v-model="isDarkMode"
                  />
                  <span class="slider round" v-tooltip="isDarkMode"></span>
                </label>
              </div>
              <div
                class="navigation-user-profile d-flex align-items-center py-1 pr-3 pl-1"
              >
                <img
                  class="rounded-circle img-fluid"
                  :src="this.$store.getters.getAvatar"
                  width="30"
                  alt=""
                />
                <p class="mb-0 ml-3">
                  {{ this.$store.getters.getUser.name }}
                </p>
                <div class="dropdown">
                  <div
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v ml-3 text-white"></i>
                  </div>

                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#" @click="logout()"
                      >Logout</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  mounted() {
    if (localStorage.theme && localStorage.theme === 'dark' && !this.isDarkMode)
      this.$store.commit('toggleDarkMode');

    this.changeStyle();
  },
  methods: {
    logout() {
      setTimeout(() => {
        this.$store.commit('removeToken');
        this.$router.push({ path: '/' });
      }, 1500);
    },
    sidebarChange(sidebar) {
      if (sidebar) {
        this.$store.commit('sidebar', false);
      } else {
        this.$store.commit('sidebar', true);
      }
    },
    changeStyle() {
      if (this.isDarkMode) {
        localStorage.theme = 'dark';
        let darkThemeLinkEl = document.createElement('link');
        darkThemeLinkEl.setAttribute('rel', 'stylesheet');
        darkThemeLinkEl.setAttribute('href', '/css/dark.css');
        darkThemeLinkEl.setAttribute('id', 'dark-theme-style');

        let docHead = document.querySelector('head');
        docHead.append(darkThemeLinkEl);
      } else {
        localStorage.theme = 'light';
        let darkThemeLinkEl = document.querySelector('#dark-theme-style');
        if (darkThemeLinkEl) {
          let darkThemeLinkEl = document.querySelector('#dark-theme-style');
          let parentNode = darkThemeLinkEl.parentNode;
          parentNode.removeChild(darkThemeLinkEl);
        }
      }

      if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
      }
    }
  },
  computed: {
    sidebar() {
      return this.$store.state.sidebar;
    },
    isDarkMode: {
      get() {
        return this.$store.state.isDarkMode;
      },
      set() {
        this.$store.commit('toggleDarkMode');
      }
    },
    logo() {
      const dark = this.isDarkMode ? '-dark' : '';
      return require(`@/assets/img/login/exec${dark}.png`);
    }
  }
};
</script>

<style></style>
