<template>
  <div
    class="p-login py-5"
    id="login"
    style="background-image:url('images/login-bg.jpg')"
  >
    <div class="p-login-container mx-auto">
      <div class="row">
        <div class="col-md-7">
          <div class="c-login-tagline">
            <p class="login-title mb-0 primary-font">Selamat Datang di</p>
            <p class="login-subtitle mb-0 primary-font">Executive Dashboard</p>
          </div>
        </div>
        <div class="col-md-5">
          <child />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if (this.$store.getters.getToken != '' && window.base_segment == '') {
      this.$router.push({ path: '/disaster' });
    }
    if (this.$store.getters.getToken == '') {
      this.$router.push({ path: '/' });
    }
  }
};
</script>

<style></style>
